import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import _ from 'lodash';

import { createIdea, updateIdea, getMessagesByIssuesIds, createIssuesWithMessages } from '../../../api/detailsMatrix';
import { getGeneratedNamesForIdea, getGeneratedChallenges, getGeneratedBenefits, getGeneratedImage } from '../../../api/ai';
import { uploadImage } from '../../../api/uploads';

import Dialog from '../Dialog';
import DependencyModalHeader from './DependencyModalHeader';
import DependencyModalTabs from './DependencyModalTabs';
import WarningNotTokensModal from '../../ComponentsDashboard/WarningNotTokensModal';
import BuyMoreAICreditsModal from '../BuyMoreAICreditsModal';
import CheckoutModal from '../CheckoutModal';
import { setGeneratedOpenAIRequests } from '../../../actions/projectActions';
import DependencyModalFooter from './DependencyModalFooter';
import ErrorMessageModal from '../../../components/dialogs/ErrorMessageModal';
import { getTranslation } from '../../../helpers/getLanguage';

import './styles.css';

const initialState = {
    existingDependency: null,
    createsBenefits: null,
    isIdeaNew: null,
    isIdeaFeasible: null,
    score: null,
    feasibleScore: null,
    benefits: [{ name: '', description: '', target: [], userInfo: '' }],
    issues: [{ comment: '', description: '', createdByUser: '' }],
    issuesMessages: [],
    ideaName: '',
    comment: '',
    image: { name: '', originalName: '', id: '' },
    imagesLibrary: [],
};

const initialUpload = {
    progress: 100,
    cancel: null,
    name: '',
    date: '',
    size: 0,
    uploaded: false,
    uploadName: '',
};

const initialPaymentInfo = {
    intervalCount: 1,
    quantity: 1,
    key: '',
    interval: null,
    openAIRequests: null,
    total: null,
    promoCode: '',
    promoCodeOpenAI: '',
};

const isDefaultCategory = (value) => ['Consumer', 'Company', 'Other'].includes(value);

const DependencyEditModal = ({
    closeDialog,
    edit,
    rowAttribute,
    selectedComponentRow,
    selectedComponentCol,
    colAttribute,
    productId,
    detailsMatrix,
    teamId,
    isReversed,
    categories = [],
    productType,
    productName,
    projectName,
    language,
    history,
    setGeneratedOpenAIRequests,
    ...props
}) => {
    const [dependency, setDependency] = useState(_.cloneDeep(initialState));
    const [invertedDependency, setInvertedDependency] = useState(_.cloneDeep(initialState));
    const [currentTab, setCurrentTab] = useState(0);
    const reverseEnabled = (rowAttribute || {}).internal || (isReversed && (colAttribute || {}).internal);
    const [reversed, setReversed] = useState(reverseEnabled && Boolean(isReversed));
    const [isNew, setIsNew] = useState(!edit);
    const [detailsMatrixId, setDetailsMatrixId] = useState(detailsMatrix.id);
    const [reversedDetailsMatrixId, setReversedDetailsMatrixId] = useState(detailsMatrix?.reversed?.id);
    const [savedDependency, setSavedDependency] = useState({
        first: _.cloneDeep(initialState),
        second: _.cloneDeep(initialState),
    });
    const [isPending, setIsPending] = useState({ create: false, update: false });
    const [uploadInfo, setUploadInfo] = useState({ upload: { ...initialUpload }, reversed: { ...initialUpload } });
    const [isClosing, setIsClosing] = useState(false);
    const [customCategories, setCustomCategories] = useState([...categories]);
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [messages, setMessages] = useState([]);
    const [openGenerateModal, setOpenGenerateModal] = useState(false);
    const [suggestedOptions, setSuggestedOptions] = useState([]);
    const [suggestedLoading, setSuggestedLoading] = useState(false);
    const [error, setError] = useState({
        openErrorModal: false,
        message: '',
        headerError: ''
    });
    const [selectedGenerateOptions, setSelectedGenerateOptions] = useState(new Set());
    const [isOpenGenerateImageModal, setIsOpenGenerateImageModal] = useState(false);
    const [generatedImage, setGeneratedImage] = useState('');
    const [potentialGeneratedImageFile, setPotentialGeneratedImageFile] = useState(null)
    const [potentialGeneratedImageUrl, setPotentialGeneratedImageUrl] = useState("")
    const [newGeneratedImage, setNewGeneratedImage] = useState({
        isNew: false,
        urlImage: ''
    });
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
    const [isOpenBuyCreditsgModal, setIsOpenBuyCreditsgModal] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState(initialPaymentInfo);

    const getDependency = (isInverted = reversed) => {
        return isInverted ? invertedDependency : dependency;
    };

    const disabledNextButton = (currentTab === 1) && (getDependency().score === null || getDependency().score === undefined);

    useEffect(() => {
        let componentExists = true;
        const issuesParams = detailsMatrix.issues ? detailsMatrix.issues.map((issue) => issue.id) : [];
        const reversedParams = detailsMatrix.reversed ? detailsMatrix.reversed.issues.map((issue) => issue.id) : [];
        const params = { issues: [...issuesParams, ...reversedParams] };

        const onSuccess = (response) => {
            if (componentExists) setMessages((prev) => [...prev, ...response]);
        };

        const onError = (error) => {
            console.error(error);
            handleClose(true, { ...isPending, update: false });
        };

        getMessagesByIssuesIds(params, onSuccess, onError);

        return () => (componentExists = false);
    }, []);

    useEffect(() => {
        if (edit) updateValues();
    }, [detailsMatrix, edit, messages]);

    useEffect(() => {
        if (!isClosing) {
            const isUpToDate =
                savedDependency.first.image.name === dependency.image.name &&
                savedDependency.second.image.name === invertedDependency.image.name;
            if (!isUpToDate) {
                handleSave();
            }
        }
    }, [dependency.image, invertedDependency.image, isClosing, savedDependency]);

    const handleDependencyChange = (e) => {
        const currentDependency = getDependency();
        if (e) {
            handleSetDependency({
                ...currentDependency,
                existingDependency: e,
                isIdeaNew: null,
                isIdeaFeasible: null,
                score: 0,
            });
        } else {
            const isIdeaNewValue = e === null ? null : true;
            handleSetDependency({
                ...currentDependency,
                existingDependency: e,
                createsBenefits: null,
                isIdeaNew: isIdeaNewValue,
                isIdeaFeasible: null,
                score: 0,
            });
        }
    };

    const getStatus = (isInverted = false) => {
        const currentDependency = getDependency(isInverted);
        const { 
            createsBenefits, 
            isIdeaNew, 
            isIdeaFeasible, 
            score, 
            feasibleScore, 
            issues, 
            benefits, 
            comment,
            ideaName,
            image 
        } = currentDependency;

        if (isIdeaNew === false || createsBenefits === false || isIdeaFeasible === false || score === 0) {
            return 2;
        };

        if (isIdeaFeasible && score > 0) {
            return 3;
        };

        if (!createsBenefits 
            && !isIdeaFeasible 
            && score === null 
            && feasibleScore === null 
            && issues.length === 1 
            && !issues[0].comment.trim()
            && benefits.length === 1
            && !benefits[0].name.trim()
            && !comment.trim() 
            && !ideaName.trim()
            && !image.id)
        {
            return 0;
        };

        return 1;
    };

    const getIssuesWithMessages = (isInverted = reversed) => {
        const currentDependency = getDependency(isInverted);
        const deletedIssues = currentDependency.deletedIssues ?? [];

        const getMessagesByIssueIdWithDeletedMessages = (issueId, issueIndex) => {
            const filteredMessages = currentDependency.issuesMessages.filter((message) =>
                message.issueId ? message.issueId === issueId : message.issueIndex === issueIndex
            );
            const deletedMessages = currentDependency.deletedIssuesMessages ?? [];

            return [...filteredMessages, ...deletedMessages];
        };

        return [
            ...currentDependency.issues.map((issue, index) => ({
                ...issue,
                messages: getMessagesByIssueIdWithDeletedMessages(issue.id, index),
            })),
            ...deletedIssues,
        ];
    };

    const getDependencyDetails = (isInverted = reversed) => {
        const currentDependency = getDependency(isInverted);

        return {
            dependencyName: currentDependency.ideaName,
            potentialBenefitsOfDependency: currentDependency.benefits.filter((el) => el.name),
            typeDependency: currentDependency.isIdeaFeasible,
            comments: currentDependency.comment,
            rating: currentDependency.score,
            feasibleRating: currentDependency.feasibleScore,
            image: {...currentDependency.image},
            imagesLibrary: currentDependency.imagesLibrary,
            status: getStatus(isInverted),
            dependency: currentDependency.existingDependency,
            createBenefits: currentDependency.createsBenefits,
            potentialBenefits: currentDependency.isIdeaNew,
        };
    };

    const handleClose = (shouldClose, pendingState) => {
        setIsPending(pendingState);
        if (shouldClose) {
            resetDetailsMatrix();
            handleCloseDialog();
        }
    };

    const createDetails = (shouldClose = false) => {
        if (isPending.create) {
            return;
        }

        shouldClose && setIsClosing(true);

        const params = {
            customCategories,
            idAttributeRow: rowAttribute.id,
            idAttributeCol: colAttribute.id,
            productId: productId,
            teamId: teamId || '',
            ...getDependencyDetails(false),
        };

        if (!compareDependencies(initialState, invertedDependency)) {
            params.reversed = getDependencyDetails(true);
        }

        params.imagesLibrary = params.imagesLibrary.map((image) => {
            const imageCopy = {...image};
            delete imageCopy.imageSource;
            return imageCopy
        })

        delete params.image.imageSource;

        setIsPending({ ...isPending, create: true });

        const onSuccess = (response) => {
            const issues = getIssuesWithMessages() || [];
            const reversedIssues = getIssuesWithMessages(true) || [];

            const issuesParams = {
                ideaId: response.id || '',
                reversedIdeaId: response.reversedId ?? reversedDetailsMatrixId ,
                issues,
                reversedIssues,
            };

            const onSuccessUpdateIssues = () => {};

            const onErrorUpdateIssues = (error) => {
                console.error(error);
            };

            if (shouldClose) {
                createIssuesWithMessages(issuesParams, onSuccessUpdateIssues, onErrorUpdateIssues);
            };

            setIsNew(false);
            setDetailsMatrixId(response.id);
            setReversedDetailsMatrixId(response.reversedId);
            handleClose(shouldClose, { ...isPending, create: false });
        };

        const onError = (error) => {
            console.error(error);
            handleClose(shouldClose, { ...isPending, create: false });
        };

        createIdea(params, onSuccess, onError);
    };

    const resetDetailsMatrix = () => {
        const currentDependency = getDependency();
        const issuesToDelete = currentDependency.issues.map((issue) => ({ id: issue.id })) || [];

        currentDependency.benefits.forEach((benefit) => {
            benefit.target.forEach(target => {
                if (isDefaultCategory(target)) return;
                
                removeCustomCategory(target);
            })
        });

        const params = {
            issues: !reversed ? issuesToDelete : [],
            ideaId: !reversed ? detailsMatrixId || '' : '',
            reversedIdeaId: reversed ? reversedDetailsMatrixId || '' : '',
            reversedIssues: reversed ? issuesToDelete : [],
        };

        const onSuccess = () => {};
        const onError = (error) => {
            console.error(error);
        };

        params.ideaId && createIssuesWithMessages(params, onSuccess, onError);
        !reversed && setDependency({ ...initialState });
        reversed && setInvertedDependency({ ...initialState });

        updateUploadInfo({ ...initialUpload }, reversed);
        setCurrentTab(0);
    };

    const updateValues = () => {
        updateDependencyDetails(false);
        detailsMatrix.reversed && updateDependencyDetails(true);
    };

    const updateDependencyDetails = (isInverted = false) => {
        const currentDependency = isInverted ? detailsMatrix.reversed : detailsMatrix;
        const key = isInverted ? 'second' : 'first';

        if (Object.keys(currentDependency).length) {
            const benefits = currentDependency.potentialBenefitsOfDependency;
            const hasBenefits = benefits && benefits.length > 0;
            const issues = currentDependency.issues;
            const hasIssues = issues && issues.length > 0;
            const issuesMessages = [
                ...messages,
                ...(currentDependency.issuesMessages ? currentDependency.issuesMessages : []),
            ];
            const hasIssuesMessages = issuesMessages && issuesMessages.length > 0;
            const image = currentDependency.image;
            const currentRating = currentDependency.rating === null ? null : currentDependency.rating === 0 ? 0 : currentDependency.rating;
            const currentFeasibleRating = currentDependency.feasibleRating === null ? null : currentDependency.feasibleRating === 0 ? 0 : currentDependency.feasibleRating;

            const params = {
                existingDependency: currentDependency.dependency,
                createsBenefits: currentDependency.createBenefits,
                isIdeaNew: currentDependency.potentialBenefits,
                isIdeaFeasible: currentDependency.typeDependency,
                score: currentRating,
                feasibleScore: currentFeasibleRating,
                benefits: (hasBenefits && benefits) || [{ name: '', description: '', target: [], userInfo: '' }],
                issues: (hasIssues && issues) || [{ comment: '', description: '', createdByUser: '' }],
                issuesMessages: (hasIssuesMessages && issuesMessages) || [],
                ideaName: currentDependency.dependencyName || '',
                comment: currentDependency.comments || '',
                image: image || initialState.image,
                imagesLibrary: currentDependency.imagesLibrary || initialState.imagesLibrary,
            };

            if (image && image.name) {
                updateUploadInfo(
                    {
                        ...initialUpload,
                        name: image.originalName,
                        date: image.date,
                        size: image.size,
                        uploadName: image.name,
                        uploaded: true,
                        id: image.id,
                    },
                    isInverted
                );
            };

            const updatedDependency = { ...getDependency(isInverted), ...params };
            handleSetDependency(updatedDependency, isInverted);
            setSavedDependency((prev) => ({ ...prev, [key]: { ...updatedDependency } }));
        } else {
            handleSetDependency({ ...initialState }, isInverted);
            setSavedDependency({ ...savedDependency, [key]: { ...initialState } });
        }
    };

    const updateDetailsMatrix = (shouldClose = false) => {
        if (!detailsMatrixId) {
            return;
        }

        shouldClose && setIsClosing(true);

        const params = {
            id: detailsMatrixId,
            idAttributeRow: rowAttribute.id,
            idAttributeCol: colAttribute.id,
            customCategories: getCustomCategories(),
            productId: productId,
            teamId: teamId || '',
            ...getDependencyDetails(false),
        };

        params.imagesLibrary = params.imagesLibrary.map((image) => {
            const imageCopy = {...image};
            delete imageCopy.imageSource;
            return imageCopy
        });

        delete params.image.imageSource;

        setIsPending({ ...isPending, update: true });

        if (!compareDependencies(initialState, invertedDependency)) {
            params.reversed = getDependencyDetails(true);
            params.reversed.imagesLibrary = params.reversed.imagesLibrary.map((image) => {
                const imageCopy = {...image};
                delete imageCopy.imageSource;
                return imageCopy
            })
        }

        const onSuccess = (response) => {
            response.reversedId && setReversedDetailsMatrixId(response.reversedId);
            handleClose(shouldClose, { ...isPending, update: false });
            setIsLoadingImage(false);
            createIssues(response.reversedId)
        };

        const onError = (error) => {
            console.error(error);
            handleClose(shouldClose, { ...isPending, update: false });
        };

        const issues = getIssuesWithMessages(false) || [];
        const reversedIssues = getIssuesWithMessages(true) || [];

        const onSuccessUpdateIssues = () => {};

        const onErrorUpdateIssues = (error) => {
            console.error(error);
        };

        updateIdea(params, onSuccess, onError);

        const createIssues = (reversedIdeaId) => {
            const issuesParams = {
                ideaId: detailsMatrixId || '',
                reversedIdeaId: reversedIdeaId ?? reversedDetailsMatrixId,
                issues,
                reversedIssues,
            };

            if (shouldClose && !isPending.update) {
                createIssuesWithMessages(issuesParams, onSuccessUpdateIssues, onErrorUpdateIssues);
            }
        }
    };

    const handleChange = (e) => {
        const currentDependency = getDependency();

        const { name, value } = e.target;
        handleSetDependency({ ...currentDependency, [name]: value });
    };

    const selectCurrentDependencyImageFromLibrary = (index) => {
        const currentDependency = getDependency();

        handleSetDependency({ ...currentDependency, image: currentDependency.imagesLibrary[index] });
    }

    const removeImageFromLibrary = (id) => {
        const currentDependency = getDependency();
        const imagesLibrary = [...currentDependency.imagesLibrary]
        const indexOfSelectedImage = imagesLibrary.findIndex((image) => image.id === id)
        
        if (id===currentDependency.image.id) {
            const newCurrentImageIndex=indexOfSelectedImage ? imagesLibrary.length % indexOfSelectedImage: imagesLibrary.length-1;
            
            handleSetDependency({ 
                ...currentDependency,
                image: imagesLibrary.length - 1 ? imagesLibrary[newCurrentImageIndex] : initialState.image,
                imagesLibrary: imagesLibrary.filter((image) => image.id !== id),
            });

            return newCurrentImageIndex
        }

        handleSetDependency({ ...currentDependency, imagesLibrary: imagesLibrary.filter((image) => image.id!==id) });

        return imagesLibrary.findIndex((image) => image.id === currentDependency.image.id)
    }

    const handleAddIssue = () => {
        const currentDependency = getDependency();

        const lastIssue = currentDependency?.issues[currentDependency?.issues?.length - 1];

        if (lastIssue?.comment || currentDependency?.issues?.length === 0) {
            handleSetDependency({ ...currentDependency, issues: [...currentDependency.issues, { comment: '', description: '', createdByUser: '' }] });
        }
    };

    const handleRemoveIssue = (index) => {
        const currentDependency = getDependency();
        const filteredIssues = currentDependency.issues.filter((issue, issueIndex) => issueIndex !== index);
        const filteredMessages = currentDependency.issuesMessages.map((message) => {
            if (message.issueIndex > index) {
                return { ...message, issueIndex: --message.issueIndex };
            } else if (message.issueIndex === index) {
                return {};
            }
            return message;
        });
        handleSetDependency({
            ...currentDependency,
            issues: [...filteredIssues],
            issuesMessages: [...filteredMessages],
            deletedIssues: [
                ...(currentDependency.deletedIssues ? currentDependency.deletedIssues : []),
                { id: currentDependency.issues[index].id },
            ],
        });
    };

    const removeLastIssue = (index, idIssues) => {
        const currentDependency = getDependency();
        const foundIssue = currentDependency.issues.find((_, issueIndex) => issueIndex === index);

        let filteredMessages = [];

        if (idIssues) {
            filteredMessages = currentDependency.issuesMessages.filter((message) => idIssues !== message.issueId);
        } else {
            filteredMessages = currentDependency.issuesMessages.filter((message) => message.issueIndex !== index);
        };
            
        foundIssue.comment = '';
        foundIssue.description = '';
        foundIssue.createdByUser = '';

        handleSetDependency({
            ...currentDependency,
            issues: [...currentDependency.issues],
            issuesMessages: [...filteredMessages],
            deletedIssues: [
                ...(currentDependency.deletedIssues ? currentDependency.deletedIssues : []),
                { id: currentDependency.issues[index].id },
            ],
        });
    };

    const removeMessage = (indexMessage, idSavedMessage) => {
        const currentDependency = getDependency();
        let filteredMessages = [];

        if (idSavedMessage) {
            filteredMessages = currentDependency.issuesMessages.filter((message) => idSavedMessage !== message._id);
        } else {
            filteredMessages = currentDependency.issuesMessages.filter((message, index) => index !== indexMessage);
        };

        handleSetDependency({
            ...currentDependency,
            issuesMessages: [...filteredMessages],
        });
    };
    
    const removeLastMessage = (indexMessage, issueId, idSavedMessage) => {
        const currentDependency = getDependency();
        let foundMessage = {};

        if (idSavedMessage) {
            foundMessage = currentDependency.issuesMessages.find((message) => idSavedMessage === message._id);
        } else {
            foundMessage = currentDependency.issuesMessages.find((message, index) => indexMessage === index && issueId === message.issueIndex);
        };

        foundMessage.message = '';

        handleSetDependency({
            ...currentDependency, 
            issuesMessages: [
                ...currentDependency.issuesMessages
            ]
        });
    };

    const handleIssueChange = (index, value, name) => {
        const currentDependency = { ...getDependency() };
        const changedIssues = [...currentDependency.issues];
        if (name === "comment") {
            changedIssues[index].comment = value;
            changedIssues[index].createdByUser = `${props.userFullName.name} ${props.userFullName.lastName}`;
        };

        if (name === "description") {
            changedIssues[index].description = value;
        };
        
        handleSetDependency({ ...currentDependency, issues: [...changedIssues] });
    };

    const handleAddIssueMessage = (issueId, issueIndex) => {
        const currentDependency = getDependency();
        const filteredMessages =
            currentDependency?.issuesMessages.filter((message) =>
                message.issueId ? message.issueId === issueId : message.issueIndex === issueIndex
            ) || [];

        const lastMessage = filteredMessages[filteredMessages.length - 1];
        const canBeCreated = !lastMessage || lastMessage.message !== '';

        if (canBeCreated) {
            handleSetDependency({
                ...currentDependency,
                issuesMessages: [...currentDependency.issuesMessages, { message: '', issueId, issueIndex }],
            });
        }
    };

    const handleIssueMessageChange = (value, index, userId, issueIndex) => {
        const currentDependency = getDependency();
        const changedIssuesMessages = [...currentDependency.issuesMessages];
        changedIssuesMessages[index] = { message: value, userId, issueIndex }; 
        handleSetDependency({ ...currentDependency, issuesMessages: changedIssuesMessages });
    };

    const updateBenefit = (name, index, dependency, value) => {
        const changedBenefits = [...dependency.benefits];
        const newBenefit = { ...changedBenefits[index] };
        newBenefit[name] = value;
        changedBenefits[index] = newBenefit;
        handleSetDependency({ ...dependency, benefits: changedBenefits });
    };

    const handleTargetChange = (index, { id, name }) => {
        const currentDependency = getDependency();

        const updatedTarget = [...currentDependency.benefits[index].target];
        const indexUpdatedTarget = updatedTarget.findIndex(target => target.name === name);

        if (indexUpdatedTarget === -1) {
            updatedTarget.push({ id, name });
        } else {
            updatedTarget.splice(indexUpdatedTarget, 1);
        }

        updateBenefit("target", index, currentDependency, updatedTarget);
    };

    const handleBenefitChange = (index, value, name) => {
        const currentDependency = getDependency();
        updateBenefit(name, index, currentDependency, value);
    }

    const handleDeleteOption = (changedLabel) => {
        const currentDependency = getDependency();

        currentDependency.benefits.forEach((benefit) => {
            const updatedTargets = benefit.target.filter((targetElement) => targetElement.name !== changedLabel);
            benefit.target = updatedTargets
        });

        handleSetDependency({
            ...currentDependency,
            benefits: currentDependency.benefits
        })

        removeCustomCategory(changedLabel);
    }

    const addBenefit = () => {
        const currentDependency = getDependency();
        handleSetDependency({
            ...currentDependency,
            benefits: [...currentDependency.benefits, { name: '', description: '', target: [], userInfo: '' }],
        });
    };

    const removeBenefit = (index) => {
        const currentDependency = getDependency();
        removeCustomCategory(currentDependency.benefits[index].target);
        const filteredBenefits = currentDependency.benefits.filter((benefit, benefitIndex) => benefitIndex !== index);
        handleSetDependency({ ...currentDependency, benefits: [...filteredBenefits] });
    };

    const removeLastBenefit = (index) => {
        const currentDependency = getDependency();
        removeCustomCategory(currentDependency.benefits[index].target);
        const foundBenefit = currentDependency.benefits.find((benefit, benefitIndex) => benefitIndex === index);

        foundBenefit.name = '';
        foundBenefit.description = '';
        foundBenefit.target = [];
        foundBenefit.userInfo = '';

        handleSetDependency({
            ...currentDependency, 
            benefits: [
                ...currentDependency.benefits
            ]
        });
    };

    const attributeDependency = {
        firstComponent: selectedComponentRow || '',
        firstAttribute: (rowAttribute && rowAttribute.name) || '',
        secondComponent: selectedComponentCol || '',
        secondAttribute: (colAttribute && colAttribute.name) || '',
    };

    const handleSetCurrentTab = (value) => {
        setCurrentTab(value);
        handleSave();
    };

    const handleSave = () => {
        const upToDate =
            compareDependencies(savedDependency.first, dependency) &&
            compareDependencies(savedDependency.second, invertedDependency);

        if (!upToDate) {
            const updatedDependency = {
                first: { ...getDependency(false) },
                second: { ...getDependency(true) },
            };

            setSavedDependency(updatedDependency);
            isNew ? createDetails() : updateDetailsMatrix();
        }
    };

    const compareDependencies = (firstItem, secondItem) => {
        const keys = Object.keys(firstItem);
        let isEqual = true;

        for (let key of keys) {
            if (key === 'benefits') {
                const firstBenefit =
                    firstItem[key] && firstItem[key].filter((benefit) => benefit.name && benefit.target && benefit.description && benefit.userInfo);
                const secondBenefit =
                    secondItem[key] && secondItem[key].filter((benefit) => benefit.name && benefit.target && benefit.description && benefit.userInfo);

                if (firstBenefit.length !== secondBenefit.length) {
                    isEqual = false;
                    break;
                }

                for (let benefit of firstBenefit) {
                    const foundBenefit = secondBenefit.find(
                        (item) => item.name === benefit.name && item.target === benefit.target && item.description === benefit.description && item.userInfo === benefit.userInfo
                    );
                    if (!foundBenefit) {
                        isEqual = false;
                        break;
                    }
                }

                continue;
            }

            if (key === 'issues') {
                if (firstItem[key].join('') !== secondItem[key].join('')) {
                    isEqual = false;
                    break;
                }
                continue;
            }

            if (key === 'issuesMessages') {
                if (firstItem[key].join('') !== secondItem[key].join('')) {
                    isEqual = false;
                    break;
                }
                continue;
            }

            if (key === 'image') {
                if (firstItem[key].name !== secondItem[key].name) {
                    isEqual = false;
                    break;
                }
                continue;
            }

            if (firstItem[key] !== secondItem[key]) {
                isEqual = false;
                break;
            }
        }

        return isEqual;
    };

    const prevEnabled = () => {
        if (currentTab === 0) {
            return false;
        }

        return true;
    };

    const nextEnabled = () => {
        if (currentTab === 2) {
            return false;
        }
        return true;
    };

    const isCompleted = () => {
        const currentDependency = getDependency();
        return (
            currentDependency.createsBenefits === false ||
            currentDependency.isIdeaNew === false ||
            currentDependency.isIdeaFeasible === false ||
            (currentDependency.isIdeaFeasible === true && currentDependency.score > 0)
        );
    };

    const handleSetReversed = () => {
        const currentDependency = getDependency(!reversed);
        if (!invertedDependency) {
            setInvertedDependency(initialState);
            setCurrentTab(0);
        }

        if (currentDependency) {
            !currentDependency.createsBenefits && !currentDependency.isIdeaNew && setCurrentTab(0);
        }
        setPotentialGeneratedImageFile("");
        setPotentialGeneratedImageUrl("");
        setGeneratedImage("");
        setReversed(!reversed);
        handleSave();
    };

    const handleSetDependency = (value, isInverted = reversed) => {
        if (isInverted) {
            setInvertedDependency(value);
        } else {
            setDependency(value);
        }
    };

    const handleImageUpload = (file, imageSource = null) => {
        const isInverted = reversed;
        const CancelToken = axios.CancelToken;

        const formData = new FormData();
        formData.append('image', file, file.name);
        updateUploadInfo({ progress: 0, cancel: null, uploaded: false, name: file.name, size: file.size }, isInverted);

        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
                const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                updateUploadInfo({ progress: completed }, isInverted);
            },
            cancelToken: new CancelToken(function executor(c) {
                updateUploadInfo({ cancel: c }, isInverted);
            }),
        };

        const onSuccess = (response) => {
            updateUploadInfo(
                { uploaded: true, date: new Date(), uploadName: response.filename, id: response.imageId },
                isInverted
            );
            const image = {
                name: response.filename,
                originalName: file.name,
                id: response.imageId,
                imageSource
            };

            const currentDependency = getDependency();
            
            if (!currentDependency.imagesLibrary.length) {
                handleSetDependency({ ...currentDependency, imagesLibrary:[...currentDependency.imagesLibrary, image], image });
                return
            }
            handleSetDependency({ ...currentDependency, imagesLibrary:[...currentDependency.imagesLibrary, image] });
        };

        const onError = (error) => {
            console.error(error);
            const key = isInverted ? 'reversed' : 'upload';
            if (uploadInfo[key].name) {
                updateUploadInfo({ ...initialUpload }, isInverted);
            }
        };

        setIsLoadingImage(true);
        uploadImage(formData, config, onSuccess, onError);
    };

    const handleCancelClick = () => {
        setNewGeneratedImage({
            isNew: false,
            urlImage: ""
        });
        uploadInfo.cancel && uploadInfo.cancel('Canceled by user');
        updateUploadInfo({ ...initialUpload }, reversed);

        const currentDependency = getDependency();
        handleSetDependency({ ...currentDependency, image: initialState.image });
    };

    const updateUploadInfo = (value, isInverted) => {
        const key = isInverted ? 'reversed' : 'upload';
        if (value) {
            setUploadInfo((state) => ({ ...state, [key]: { ...state[key], ...value } }));
        } else {
            setUploadInfo((state) => ({ ...state, [key]: { ...initialUpload } }));
        }
    };

    const currentUploadInfo = reversed ? uploadInfo && uploadInfo.reversed : uploadInfo && uploadInfo.upload;

    const handleCloseDialog = () => {
        setIsClosing(true);
        const { upload, reversed } = uploadInfo;
        if (upload.name && !upload.uploaded) {
            upload.cancel && upload.cancel('Canceled by user');
        }

        if (reversed.name && !reversed.uploaded) {
            reversed.cancel && reversed.cancel('Canceled by user');
        }

        setUploadInfo({ upload: { ...initialUpload }, reversed: { ...initialUpload } });
        closeDialog && closeDialog();
    };

    const matrixBodyClass = [1, 2].includes(currentTab) ? ' benefits-container' : '';

    const addCustomCategory = (name) => {
        const foundCategory = customCategories.find((category) => category.name === name);
        if (foundCategory) {
            foundCategory.count++;
        }
        
        const currentId = customCategories[customCategories.length - 1]?.id;

        setCustomCategories([...customCategories, {
            id: _.isNumber(currentId) ? currentId + 1 : 3,
            name, 
            count: 1
        }]);
    };

    const removeCustomCategory = (name) => {
        const updatedCategories = customCategories.filter((category) => category.name !== name);
        setCustomCategories(updatedCategories);
    };

    const renameCustomCategory = (oldName, newName) => {
        const foundCategoryIndex = customCategories.findIndex((category) => category.name === newName);
        if (foundCategoryIndex !== -1) {
            customCategories[foundCategoryIndex].count++;

            return;
        }

        const dependency = getDependency();
        
        dependency.benefits.forEach(benefit => {
            const currentTargetIndex = benefit.target.findIndex(target => target.name === oldName);
            
            if (currentTargetIndex !== -1) {
                benefit.target.splice(currentTargetIndex, 1, { id: benefit.target[currentTargetIndex].id, name: newName });
            }
        }) 

        handleSetDependency({
            ...dependency,
            benefits: dependency.benefits
        })
            
        const copiedArray = [...customCategories];
        const findOldCategoryIndex = customCategories.findIndex((category) => category.name === oldName);
        
        const { count, id } = customCategories[findOldCategoryIndex];

        copiedArray.splice(findOldCategoryIndex, 1, { id, count, name: newName });
        setCustomCategories(copiedArray);
    }

    const getCustomCategories = () => {
        return customCategories.filter((category) => category.count > 0);
    };

    const addIdeasName = (ideasName) => {
        const currentDependency = getDependency()
        
        handleSetDependency({...currentDependency, ideaName: ideasName});
        setOpenGenerateModal(false);
        setSuggestedOptions([]);
    };

    const generateNames = () => {
        setOpenGenerateModal(true);

        const onSuccess = ({ parsedChoices, numberRequests }) => {
            setSuggestedOptions(parsedChoices);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = (error.response && error.response.data);
            
            if (code === "30840") {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                return
            }
            
            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
            setSuggestedLoading(false);
        }

        setSuggestedLoading(true);

        const currentDependency = getDependency();
        const description = currentDependency.comment ? currentDependency.comment : descriptionOfIdea;

        getGeneratedNamesForIdea(
            {
                projectName: productName ? productName : projectName,
                productType,
                projectId: productId,
                descriptionOfIdea: description,
                language
            },
            onSuccess, 
            onError
        );
    };

    const closeGenerateModal = () => {
        setOpenGenerateModal(false);
        setSuggestedOptions([]);
    };

    const handleChangeScore = (e) => {
        if (reversed) {
            setInvertedDependency({...invertedDependency, score: e });
            return
        }

        setDependency({...dependency, score: e});
    };

    const handleChangeFeasibleScore = (e) => {
        if (reversed) {
            setInvertedDependency({...invertedDependency,  isIdeaFeasible: e > 0, feasibleScore: e });
            return
        }

        setDependency({...dependency, isIdeaFeasible: e > 0, feasibleScore: e });
    };

    const header = {
        projectName,
        firstAttribute: reversed ? attributeDependency.secondAttribute : attributeDependency.firstAttribute,
        firstComponent: reversed ? attributeDependency.secondComponent : attributeDependency.firstComponent,
        secondAttribute: reversed ? attributeDependency.firstAttribute : attributeDependency.secondAttribute,
        secondComponent: reversed ? attributeDependency.firstComponent : attributeDependency.secondComponent,
    };

    const closeErrorWindow = () => {
        setError({
            openErrorModal: false,
            message: '',
            headerError: ''
        });
    };

    const generateChallenges = () => {
        setOpenGenerateModal(true);

        const onSuccess = ({ parsedChoices, numberRequests }) => {
            setSuggestedOptions(parsedChoices);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = (error.response && error.response.data);
            
            if (code === "30840") {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                return
            }
            
            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
            setSuggestedLoading(false);
        }

        setSuggestedLoading(true);

        const currentDependency = getDependency();
        const description = currentDependency.comment ? currentDependency.comment : descriptionOfIdea;

        getGeneratedChallenges(
            {
                projectName: productName ? productName : projectName,
                projectId: productId,
                descriptionOfIdea: description,
                language
            },
            onSuccess, 
            onError
        );
    };

    const selectGenerateOption = (nameOption) => {
        setSelectedGenerateOptions((prev) => {
            const prevSelectedOption = new Set(prev);
            const key = `${nameOption}`;
            prev.has(key) ? prevSelectedOption.delete(key) : prevSelectedOption.add(key);
            return prevSelectedOption;
        });
    };

    const addGenerateChallenges = () => {
        const generatedIssues = [];

        const currentDependency = getDependency();

        suggestedOptions.map(issue => {
            if (selectedGenerateOptions.has(`${issue}`)) {
                const params = {
                    comment: issue.includes("：") ? issue.split('：')[0] : issue.split(':')[0],
                    description: issue.includes("：") ? issue.split('：')[1] : issue.split(':')[1],
                    createdByUser: "AI"
                }
                generatedIssues.push(params);
            }
        })

        handleSetDependency({
            ...currentDependency,
            issues:[
                ...generatedIssues,
                ...currentDependency.issues,
            ],
        });

        setOpenGenerateModal(false);
        setSelectedGenerateOptions(new Set());
        setSuggestedOptions([]);
    };

    const addGenerateBenefits = () => {
        const benefits = [];

        const currentDependency = getDependency();

        suggestedOptions.map(benefit => {
            if (selectedGenerateOptions.has(`${benefit}`)) {
                const params = {
                    name: benefit.includes("：") ? benefit.split('：')[0] : benefit.split(':')[0],
                    description: benefit.includes("：") ? benefit.split('：')[1] : benefit.split(':')[1],
                    target: [],
                    userInfo: "AI"
                }
                benefits.push(params);
            }
        })

        handleSetDependency({
            ...currentDependency,
            benefits:[
                ...benefits,
                ...currentDependency.benefits,
            ],
        });

        setOpenGenerateModal(false);
        setSelectedGenerateOptions(new Set());
        setSuggestedOptions([]);
    };

    const generateBenefits = () => {
        setOpenGenerateModal(true);

        const onSuccess = ({ parsedChoices, numberRequests }) => {
            setSuggestedOptions(parsedChoices);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = (error.response && error.response.data);
            
            if (code === "30840") {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                return
            }
            
            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
            setSuggestedLoading(false);
        }

        setSuggestedLoading(true);

        const currentSubtraction = getDependency();
        const description = currentSubtraction.comment ? currentSubtraction.comment : descriptionOfIdea;

        getGeneratedBenefits(
            {
                projectName: productName ? productName : projectName,
                projectId: productId,
                descriptionOfIdea: description,
                language
            },
            onSuccess, 
            onError
        );
    };

    const handleRightClick = () => {
        const currentDependency = getDependency();

        if (currentTab === 1 
            && (currentDependency.score === null || currentDependency.score === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: `${getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_IMPACT")}`,
                headerError: getTranslation("RATING_REQUIRED_MODAL_HEADER"),
            });
            return;
        };

        handleSetCurrentTab(currentTab + 1);
    };

    const handleLeftClick = () => {
        handleSetCurrentTab(currentTab - 1);
    };

    const getMessageForCheckRating = () => {
        return (
            <p className="flex-column mt-25px">
                <span style={{ margin: "0" }}>{getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_IMPACT")}</span>
                <span>{getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_FEASIBILITY")}</span>
            </p>
        );
    };

    const checkRating = () => {
        const currentDependency = getDependency()

        if ((currentTab === 2) 
            && (currentDependency.score === null || currentDependency.score === undefined)
            && (currentDependency.feasibleScore === null || currentDependency.feasibleScore === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: getMessageForCheckRating(),
                headerError: getTranslation("RATING_REQUIRED_MODAL_HEADER"),
            });
            return;
        };

        if ((currentTab === 2) 
            && (currentDependency.score)
            && (currentDependency.feasibleScore === null || currentDependency.feasibleScore === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: `${getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_FEASIBILITY")}`,
                headerError: getTranslation("RATING_REQUIRED_MODAL_HEADER"),
            });
            return;
        };

        if ((currentTab === 2) 
            && (currentDependency.feasibleScore)
            && (currentDependency.score === null || currentDependency.score === undefined)
        ) {
            setError({
                openErrorModal: true,
                message: `${getTranslation("RATING_REQUIRED_MODAL_NOT_RATE_IMPACT")}`,
                headerError: getTranslation("RATING_REQUIRED_MODAL_HEADER")
            });
            return;
        };

        isNew ? createDetails(true) : updateDetailsMatrix(true);
    };

    const changeCurrentTab = (index) => {
        setCurrentTab(index);
    };

    const convertImage = async (imageBase64) => {
        try {
            const base64Response = await fetch(`data:image/png;base64,${imageBase64}`);
            const blobImage = await base64Response.blob();

            let dt = new DataTransfer();
            dt.items.add(new File([blobImage], 'image.png', { type: blobImage.type }));
            const fileImage = dt.files[0];
            const bufferImage = await fileImage.arrayBuffer();
    
            const reader = new FileReader();
    
            reader.onload = () => {
                const imageUrl = `data:image/png;base64,${Buffer.from(bufferImage).toString('base64')}`;
                setPotentialGeneratedImageUrl(imageUrl);
            };
    
            reader.readAsArrayBuffer(fileImage);
    
            return fileImage;
        } catch (error) {
            setError({
                openErrorModal: true,
                message: error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
        };
    };

    const handleAcceptPotentialImage = () => {
        setGeneratedImage(potentialGeneratedImageUrl);
        handleImageUpload(potentialGeneratedImageFile, potentialGeneratedImageUrl);
        setIsOpenGenerateImageModal(false);
    }

    const generateImage = () => {
        setIsOpenGenerateImageModal(true);

        const onSuccess = async (imageBase64) => {
            setNewGeneratedImage({
                isNew: true,
                urlImage: `data:image/png;base64,${imageBase64}`
            });
            const fileImage = await convertImage(imageBase64);

            setPotentialGeneratedImageFile(fileImage);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = (error.response && error.response.data);
            
            if (code === "30840") {
                setOpenGenerateModal(false);
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                return
            }
            
            setError({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
            setSuggestedLoading(false);
        }

        setSuggestedLoading(true);

        const currentDependency = getDependency();
        const description = currentDependency.comment ? currentDependency.comment : descriptionOfIdea;

        getGeneratedImage(
            {
                descriptionOfIdea: description,
                language
            },
            onSuccess, 
            onError
        );
    };

    const getDependencyHeader = () => {
        return (
            <p className='matrix-dialog-header-imagine'> 
                {getTranslation('SUBTRACTION_DESCRIPTION_IMAGINE_A')}
                <u>{productName ? productName : header.projectName}</u>
                {getTranslation("DESCRIBE_NEW_DEPENDENCY_WHERE_THE")}
                <u>{header.secondAttribute}</u>
                {getTranslation('DEPENDENCY_MODAL_DEPENDENCY_DESCRIPTION_OF_THE')}
                <u>{header.secondComponent}</u>
                {productType === 'process' && 
                    <span style={{marginRight: '10px'}}>{getTranslation('SUBTRACTION_MODAL_HEADER_STEP')}</span>
                }
                {getTranslation('DEPENDENCY_MODAL_DEPENDENCY_DESCRIPTION_DEPENDS')}
                <u>{header.firstAttribute}</u>
                {getTranslation('DEPENDENCY_MODAL_DEPENDENCY_DESCRIPTION_OF_THE')}
                <u>{header.firstComponent}</u>
                {productType === 'process' && 
                    <span>{getTranslation('SUBTRACTION_MODAL_HEADER_STEP')}</span>
                }
            </p>
        );
    };

    const descriptionOfIdea = `
        ${productName ? productName : header.projectName} 
        ${getTranslation("DESCRIBE_NEW_DEPENDENCY_WHERE_THE")}
        ${header.secondAttribute} 
        ${getTranslation('DEPENDENCY_MODAL_DEPENDENCY_DESCRIPTION_OF_THE')} 
        ${header.secondComponent} 
        ${productType === 'process' ? getTranslation('SUBTRACTION_MODAL_HEADER_STEP') : ''}
        ${getTranslation('DEPENDENCY_MODAL_DEPENDENCY_DESCRIPTION_DEPENDS')} 
        ${header.firstAttribute} 
        ${getTranslation('DEPENDENCY_MODAL_DEPENDENCY_DESCRIPTION_OF_THE')}
        ${header.firstComponent} 
        ${productType === 'process' ? getTranslation('SUBTRACTION_MODAL_HEADER_STEP') : ''}
    `.replace(/[\s]+/gi, ' ');

    return (
        <Dialog closeDialog={handleCloseDialog} closeOnClickOutside={false}>
            <div className='matrix-dialog-wrapper'>
                <DependencyModalHeader
                    closeDialog={handleCloseDialog}
                    dependency={getDependency()}
                    currentTab={currentTab}
                    setCurrentTab={handleSetCurrentTab}
                    reversed={reversed}
                    handleSetReversed={handleSetReversed}
                    reverseEnabled={reverseEnabled}
                    productType={productType}
                    header={header}
                    productName={productName}
                    getDependencyHeader={getDependencyHeader}
                    changeCurrentTab={changeCurrentTab}
                />

                <div className={'matrix-dialog-body flex-column' + matrixBodyClass}>
                    <DependencyModalTabs
                        selectCurrentDependencyImageFromLibrary={selectCurrentDependencyImageFromLibrary}
                        removeImageFromLibrary={removeImageFromLibrary}
                        currentTab={currentTab}
                        dependency={getDependency()}
                        userId={props.userId}
                        userFullName={props.userFullName}
                        handleDependencyChange={handleDependencyChange}
                        setDependency={handleSetDependency}
                        handleChange={handleChange}
                        addBenefit={addBenefit}
                        removeBenefit={removeBenefit}
                        removeLastBenefit={removeLastBenefit}
                        handleBenefitChange={handleBenefitChange}
                        handleTargetChange={handleTargetChange}
                        addIssue={handleAddIssue}
                        potentialGeneratedImageUrl={potentialGeneratedImageUrl}
                        handleAcceptPotentialImage={handleAcceptPotentialImage}
                        removeIssue={handleRemoveIssue}
                        removeLastIssue={removeLastIssue}
                        removeMessage={removeMessage}
                        removeLastMessage={removeLastMessage}
                        handleIssueChange={handleIssueChange}
                        reversed={reversed}
                        uploadInfo={currentUploadInfo}
                        newGeneratedImage={newGeneratedImage}
                        handleCancelClick={handleCancelClick}
                        handleFileSelect={handleImageUpload}
                        customCategories={getCustomCategories()}
                        isLoadingImage={isLoadingImage}
                        addIssueMessage={handleAddIssueMessage}
                        handleIssueMessageChange={handleIssueMessageChange}
                        productType={productType}
                        header={header}
                        handleGenerateNames={generateNames}
                        openGenerateModal={openGenerateModal}
                        closeGenerateModal={closeGenerateModal}
                        suggestedOptions={suggestedOptions}
                        suggestedLoading={suggestedLoading}
                        addIdeasName={addIdeasName}
                        handleChangeScore={handleChangeScore}
                        handleChangeFeasibleScore={handleChangeFeasibleScore}
                        selectGenerateOption={selectGenerateOption}
                        selectedGenerateOptions={selectedGenerateOptions}
                        addGenerateBenefits={addGenerateBenefits}
                        handleGenerateBenefits={generateBenefits}
                        handleGenerateChallenges={generateChallenges}
                        addGenerateChallenges={addGenerateChallenges}
                        handleGenerateImage={generateImage}
                        isOpenGenerateImageModal={isOpenGenerateImageModal}
                        closeDialogGenerateImage={() => setIsOpenGenerateImageModal(false)}
                        generatedImage={generatedImage}
                        handleDeleteOption={handleDeleteOption}
                        handleRenameOption={renameCustomCategory}
                        addCustomCategory={addCustomCategory}
                    />
                </div>

                <DependencyModalFooter
                    resetDetailsMatrix={resetDetailsMatrix}
                    prevEnabled={prevEnabled}
                    nextEnabled={nextEnabled}
                    isCompleted={isCompleted}
                    handleRightClick={handleRightClick}
                    handleLeftClick={handleLeftClick}
                    disabledNextButton={disabledNextButton}
                    checkRating={checkRating }
                    currentTab={currentTab}
                />

                {error.openErrorModal &&
                    <ErrorMessageModal 
                        message={error.message}
                        closeModalWindow={closeErrorWindow}
                        textButton={getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT")}
                        handleButton={closeErrorWindow}
                        header={error.headerError}
                    />
                }

                {paymentInfo.key && (
                    <CheckoutModal
                        isOpenAISubscription
                        closeDialog={() => setPaymentInfo((prev) => ({ ...prev, key: '' }))}
                        subscriptionInfo={paymentInfo}
                        history={history}
                        mode='payment'
                        setGeneratedOpenAIRequests={setGeneratedOpenAIRequests}
                    />
                )}

                {isOpenBuyCreditsgModal && (
                    <BuyMoreAICreditsModal
                        setPaymentInfo={setPaymentInfo}
                        closeDialog={() => setIsOpenBuyCreditsgModal(false)}
                    />
                )}

                {isOpenWarningModal && (
                    <WarningNotTokensModal
                        closeDialog={() => {
                            setIsOpenWarningModal(false);
                        }}
                        openBuyModal={() => setIsOpenBuyCreditsgModal(true)}
                        history={history}
                    />
                )}
            </div>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    language: state.auth.userInfo.language,
});

const mapDispatchToProps = {
    setGeneratedOpenAIRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(DependencyEditModal);
