import { ReactComponent as AdminIcon } from '../../../assets/images/nav-admin.svg';
import { ReactComponent as AttributeDependency } from '../../../assets/images/nav-attribute-dependency.svg';
import { ReactComponent as Components } from '../../../assets/images/nav-components.svg';
import { ReactComponent as Dashboard } from '../../../assets/images/nav-dashboard.svg';
import { ReactComponent as Eureka } from '../../../assets/images/nav-eureka.svg';
import { ReactComponent as Multiplication } from '../../../assets/images/nav-multiplication.svg';
import { ReactComponent as Project } from '../../../assets/images/nav-projects.svg';
import { ReactComponent as Replacement } from '../../../assets/images/nav-replacement.svg';
import { ReactComponent as Reports } from '../../../assets/images/nav-reports.svg';
import { ReactComponent as Subtraction } from '../../../assets/images/nav-subtraction.svg';
import { ReactComponent as Users } from '../../../assets/images/nav-users.svg';
import { ReactComponent as Division } from '../../../assets/images/nav-division.svg';

export const projectSidenavItems = [
    {
        label: 'NAV_MENU_DASHBOARD',
        value: 'dashboard',
        icon: Dashboard,
        baseUrl: '/project/:projectId',
        children: [],
    },
    {
        label: 'NAV_MENU_COMPONENTS',
        value: 'components',
        icon: Components,
        baseUrl: '/project/:projectId/components',
        children: [],
    },
    {
        label: 'NAV_MENU_REPORTS',
        value: 'reports',
        icon: Reports,
        baseUrl: '/project/:projectId/report',
        children: [],
    },
    {
        label: 'NAV_MENU_ATTRIBUTE_DEPENDENCY',
        value: 'matrix',
        icon: AttributeDependency,
        baseUrl: '/project/:projectId/matrix',
        customLabelClass: 'attribute-dependency',
        containerClass: 'attribute-dependency-nav-item',
    },
    {
        label: 'NAV_SUBTRACTION',
        value: 'subtraction',
        icon: Subtraction,
        baseUrl: '/project/:projectId/subtraction',
        containerClass: 'subtraction-nav-item',
    },

    {
        label: 'NAV_REPLACEMENT',
        value: 'replacement',
        icon: Replacement,
        baseUrl: '/project/:projectId/replacement',
        containerClass: 'replacement-nav-item',
    },
    {
        label: 'NAV_MULTIPLICATION',
        value: 'multiplication',
        icon: Multiplication,
        baseUrl: '/project/:projectId/multiplication',
        containerClass: 'multiplication-nav-item',
    },
    {
        label: 'NAV_DIVISION',
        value: 'division',
        icon: Division,
        baseUrl: '',
        containerClass: 'division-nav-item',
    },
    {
        label: 'NAV_EUREKA',
        value: 'eureka',
        icon: Eureka,
        baseUrl: '/project/:projectId/eureka',
        containerClass: 'eureka-nav-item',
    },
];

export const userSidenavItem = {
    label: 'NAV_MENU_USERS',
    value: 'users',
    icon: Users,
    baseUrl: '/project/:projectId/users',
};

export const defaultSidenavItems = [
    {
        label: 'NAV_MENU_ALL_PROJECTS',
        value: 'projects',
        icon: Project,
        baseUrl: '/',
        containerClass: 'projects-nav-item',
        strong: true,
    },
];

export const adminSidenavItems = [
    {
        label: 'NAV_MENU_ADMIN_PANEL',
        value: 'admin',
        icon: AdminIcon,
        baseUrl: '/admin',
        strong: true,
    },
    {
        label: 'NAV_MENU_ALL_PROJECTS',
        value: 'projects',
        icon: Project,
        baseUrl: '/',
        containerClass: 'projects-nav-item',
        strong: true,
    },
];

export const pageUrl = {
    attributeDependency: '/dependency',
    dashboard: '/project/[0-9a-fA-F-]+$',
    projects: '(/|/invite/[0-9a-fA-F-]+)$',
    matrix: '/project/[0-9a-fA-F-]+/matrix',
    subtraction: '/project/[0-9a-fA-F-]+/subtraction',
    multiplication: '/project/[0-9a-fA-F-]+/multiplication',
    replacement: '/project/[0-9a-fA-F-]+/replacement',
    eureka: '/project/[0-9a-fA-F-]+/eureka',
    components: '/project/[0-9a-fA-F-]+/components',
    reports: '/project/[0-9a-fA-F-]+/report',
    users: '/users',
    profile: '/profile+$',
    admin: '/admin+$',
    termsOfService: '/terms-of-services',
    privacyPolicy: '/privacy-policy',
    faq: '/frequently-asked-question',
    default: '',
};
