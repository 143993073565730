import classnames from 'classnames';
import React, { useState } from 'react';

import { getTranslation } from '../../../helpers/getLanguage';
import ConfirmationModal from '../../dialogs/ConfirmationModal';

import './styles.css';

const NavItem = ({
    option,
    activePath,
    handlePathChange,
    compact,
    disabled,
    additionalClass,
    showUserItem,
    nextOption,
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const activeClass = compact ? ' compact' : '';
    const isActive = activePath === option.value;
    const isAllProjects = option.value === 'projects';
    const isDivision = option.value === 'division';
    const isUsers = showUserItem && option.value === 'users';
    const isReports = !showUserItem && option.value === 'reports';
    const labelClass = option.customLabelClass ? ` ${option.customLabelClass}` : '';

    const calculateCurrentColor = (value) => {
        if (value === 'matrix') return '#f1666a';
        if (value === 'subtraction') return '#5ac57a';
        if (value === 'replacement') return '#44ade2';
        if (value === 'multiplication') return '#8c94bf';
        if (value === 'eureka') return '#dbb785';

        return isActive ? 'white' : '#8a93c3';
    };

    const handleClick = () => {
        if (!option.baseUrl) return setModalOpen(true);
        if (!disabled || option.strong) handlePathChange(option);
    };

    const sideNavItemClassName = classnames(
        'sidenav-item flex align-center position-relative',
        { active: isActive },
        { compact: compact },
        { disabled: disabled && !option.strong },
        { pointer: !disabled || option.strong }
    );

    const closeDialog = () => {
        setModalOpen(false);
    };

    return (
        <>
            {modalOpen && (
                <ConfirmationModal
                    closeDialog={closeDialog}
                    message={getTranslation('TOOLTIP_MESSAGE_DIVISION')}
                    buttonText={'CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT'}
                    onConfirm={closeDialog}
                    messageWidth={440}
                    messageHeight={60}
                />
            )}
            <div className={sideNavItemClassName + (additionalClass ?? '')} onClick={handleClick}>
                {option.icon ? (
                    <option.icon color={calculateCurrentColor(option.value)} />
                ) : (
                    <div className={option.containerClass}></div>
                )}
                {!compact && (
                    <div className='flex align-center justify-space-between width-100'>
                        <span className={'sidenav-title flex' + labelClass}>{getTranslation(option.label)}</span>
                    </div>
                )}
                {compact && (
                    <div className='sidenav-item-tooltip flex-center'>
                        <span>{getTranslation(option.label)}</span>
                    </div>
                )}
            </div>
            {(isAllProjects || isDivision || isUsers || isReports) && !disabled && (
                <hr className={'sidenav-hr' + activeClass} />
            )}
            {nextOption && nextOption.label === 'NAV_MENU_ATTRIBUTE_DEPENDENCY' && (
                <span className={'tools' + activeClass} style={{ textAlign: 'left' }}>
                    {getTranslation('NAV_TOOLS')}
                </span>
            )}
        </>
    );
};

export default NavItem;
