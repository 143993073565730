import axios from 'axios';
import { handleSuccess, handleError } from './handlers';
import { baseApiUrl } from '../common/constants';

const baseUrl = `${baseApiUrl}/auth`;

export const recoverPassword = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/reset-password-request`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const changePassword = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/reset-password`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const registerUser = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/register`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const loginUser = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/login`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const verifyEmail = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/verify-email`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};
