import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { REACT_APP_SENTRY_URL, REACT_APP_SENTRY_ENV } from './common/config';
import './css/index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import './assets/fonts/Space-Grotesk-Medium.ttf';
import './assets/fonts/Inter-VariableFont_slnt,wght.ttf';

const SENTRY_ENV = REACT_APP_SENTRY_ENV;
const SENTRY_URL = SENTRY_ENV ? REACT_APP_SENTRY_URL : null;

Sentry.init({
    dsn: SENTRY_URL,
    environment: SENTRY_ENV,
    normalizeDepth: 10,
});

ReactDOM.render(  
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
        <App />
    </Sentry.ErrorBoundary>,

    document.getElementById('root')
);

serviceWorker.unregister();
