import { setAuthToken } from '../utils';
import jwt_decode from 'jwt-decode';
import {
    GET_USER_STATUS_SUCCESS,
    GET_USER_STATUS_ERROR,
    SET_CURRENT_USER,
    USER_LOADING,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    RESET_REGISTRATION,
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    RESET_LOGIN_STATE,
    GET_USER_PROFILE_STATUS_SUCCESS,
    GET_USER_PROFILE_STATUS_ERROR,
    SET_LANGUAGE,
    SET_NUMBER_OPENAI_REQUESTS,
} from './types';

import { registerUser as requestRegisterUser, loginUser as requestLoginUser } from '../api/auth';
import { getRoles, getProfile } from '../api/users';

import { clearProjectStore } from './projectActions';
import WebSocketInstance from '../helpers/socketInstance';

export const registerUser = (userData) => (dispatch) => {
    dispatch({ type: REGISTER_USER });
    const onSuccess = (response) => dispatch({ type: REGISTER_USER_SUCCESS, payload: response });
    const onError = (error) => dispatch({ type: REGISTER_USER_ERROR, payload: error.response && error.response.data });

    requestRegisterUser(userData, onSuccess, onError);
};

export const resetRegistration = () => {
    return {
        type: RESET_REGISTRATION,
    };
};

export const loginUser = (userData) => (dispatch) => {
    dispatch({ type: LOGIN_USER });

    const onSuccess = (response) => {
        const { token, language } = response;
        localStorage.setItem('jwtToken', token);
        localStorage.setItem('shouldValidateSubscriptions', true);
        setAuthToken(token);
        const decoded = jwt_decode(token);
        dispatch({ type: LOGIN_USER_SUCCESS });
        dispatch(setCurrentUser(decoded));
        dispatch(setLanguage(language));

        if (!WebSocketInstance.currentSocket) {
            WebSocketInstance.connect(token);
        }
    };

    const onError = (error) => dispatch({ type: LOGIN_USER_ERROR, payload: error.response && error.response.data });

    requestLoginUser(userData, onSuccess, onError);
};

export const resetLoginState = () => {
    return { type: RESET_LOGIN_STATE };
};

export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};

export const setUserLoading = () => {
    return {
        type: USER_LOADING,
    };
};

export const logoutUser = () => (dispatch) => {
    localStorage.removeItem('jwtToken');
    setAuthToken(false);
    dispatch(setCurrentUser({}));
    dispatch(clearProjectStore());

    WebSocketInstance.disconnect();
};

export const getCurrentUser = () => (dispatch) => {
    const token = localStorage.jwtToken;
    if (token) {
        setAuthToken(token);
        const decoded = jwt_decode(token);
        dispatch(setCurrentUser(decoded));
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            dispatch(logoutUser());
            window.location.href = './login';
        }
    }
};

export const getUserProfile = () => (dispatch) => {
    const params = {};

    const onSuccess = (response) => {
        dispatch({ type: GET_USER_PROFILE_STATUS_SUCCESS, payload: response });
        dispatch(setLanguage(response.userInfo.language));
        dispatch({ type: SET_NUMBER_OPENAI_REQUESTS, payload: response.numberOpenAIRequests });
    };
    const onError = (error) => {
        dispatch({ type: GET_USER_PROFILE_STATUS_ERROR });
        if (error.response && error.response.data && error.response.data.code === '10201') {
            dispatch(logoutUser());
        }
    };

    getProfile(params, onSuccess, onError);
};

export const getUserRoles = () => (dispatch) => {
    const params = {};

    const onSuccess = (response) => dispatch({ type: GET_USER_STATUS_SUCCESS, payload: response });
    const onError = (error) => {
        dispatch({ type: GET_USER_STATUS_ERROR });
        if (error.response && error.response.data && error.response.data.code === '10201') {
            dispatch(logoutUser());
        }
    };

    getRoles(params, onSuccess, onError);
};

export const updateAuthToken = (token) => (dispatch) => {
    localStorage.setItem('jwtToken', token);
    setAuthToken(token);
    const decoded = jwt_decode(token);
    dispatch(setCurrentUser(decoded));
};

export const setLanguage = (lng) => {
    return {
        type: SET_LANGUAGE,
        payload: lng,
    };
};
