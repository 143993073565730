import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { canProjectBeCirculated } from '../../../common/project';
import { createProject as requestCreateProject, updateProject as requestUpdateProject } from '../../../api/projects';
import { getGeneratedImageForProject } from '../../../api/ai';
import { uploadImage } from '../../../api/uploads';
import { getTranslation } from '../../../helpers/getLanguage';

import GenerateImageModal from '../GenerateImageModal';
import ImageModal from '../ImageModal';
import Dialog from '../Dialog';
import RadioInput from '../../inputs/CustomRadioInput';
import CustomInput from '../../inputs/CustomInput';
import CustomTextarea from '../../inputs/CustomTextarea';
import ImageUploader from '../../inputs/ImageUploader';
import CustomSwitch from '../../inputs/CustomSwitch';
import CustomNumberInput from '../../inputs/CustomNumberInput';
import ConfirmationModal from '../ConfirmationModal';
import WarningNotTokensModal from '../../ComponentsDashboard/WarningNotTokensModal';
import BuyMoreAICreditsModal from '../BuyMoreAICreditsModal';
import CheckoutModal from '../CheckoutModal';
import { setGeneratedOpenAIRequests } from '../../../actions/projectActions';
import ErrorMessageModal from '../ErrorMessageModal';
import { ImagesCarousel } from '../../inputs/ImagesCarousel';

import ProjectIcon from '../../../assets/images/project-icon.svg';
import CloseIcon from '../../../assets/images/close-icon.svg';

import { ReactComponent as ProductImage } from '../../../assets/images/product.svg';
import { ReactComponent as ServiceImage } from '../../../assets/images/service.svg';
import { ReactComponent as ProcessImage } from '../../../assets/images/process.svg';
import { ReactComponent as AIGenerateIcon } from '../../../assets/images/ai-generate-icon.svg';

import './styles.css';

export const projectOptions = [
    { label: 'PROJECT_OPTIONS_PRODUCT', value: 'product', FirstImage: ProductImage },
    { label: 'PROJECT_OPTIONS_SERVICE', value: 'service', FirstImage: ServiceImage },
    { label: 'PROJECT_OPTIONS_PROCESS', value: 'process', FirstImage: ProcessImage },
];

const initialState = {
    name: '',
    description: '',
    image: {
        name: '',
        originalName: '',
        date: '',
        size: '',
        id: '',
    },
    imagesLibrary: [],
    type: '',
    typeName: '',
    groupsPerProject: 0,
    peoplePerGroup: 0,
    canWorkIndividually: true,
    canChangeTeams: true,
};

const initialPaymentInfo = {
    intervalCount: 1,
    quantity: 1,
    key: '',
    interval: null,
    openAIRequests: null,
    total: null,
    promoCode: '',
    promoCodeOpenAI: '',
};

const initialUpload = {
    progress: 100,
    cancel: null,
    name: '',
    date: '',
    size: 0,
    uploaded: false,
};

const initialErrors = {
    name: false,
    type: false,
    typeName: false,
};

const labels = ['EDIT_PROJECT_DIALOG_LABEL_YES', 'EDIT_PROJECT_DIALOG_LABEL_NO'];
const MAX_LIMIT = 999999999;
const numberInputLabels = { 0: 'UNLIMITED' };

const validateOutgoingTeamsCount = (value) => {
    if (value < 1) return MAX_LIMIT;
    return value;
};

const validateIncomingTeamsCount = (value) => {
    if (value === MAX_LIMIT) return 0;
    return value;
};

const ProjectEditModal = ({
    closeDialog,
    edit = false,
    currentProject,
    onSuccess,
    openTab = 0,
    setProjects,
    match,
    moveProjectToFolder,
    isProcessType,
    language,
    history,
    setGeneratedOpenAIRequests
}) => {
    const [project, setProject] = useState(initialState);
    const [currentTab, setCurrentTab] = useState(openTab);
    const [uploadInfo, setUploadInfo] = useState(initialUpload);
    const [isPending, setIsPending] = useState(false);
    const [openModal, setOpenModal] = useState({ error: '' });
    const [errors, setErrors] = useState(initialErrors);
    const [isOpenGenerateImageModal, setIsOpenGenerateImageModal] = useState(false);
    const [generatedImage, setGeneratedImage] = useState('');
    const [errorMessage, setErrorMessage] = useState({
        openErrorModal: false,
        message: '',
        headerError: '',
    });
    const [isOpenImage, setIsOpenImage] = useState(false);
    const [pathImage, setPathImage] = useState('');
    const [potentialGeneratedImageFile, setPotentialGeneratedImageFile] = useState(null);
    const [potentialGeneratedImageUrl, setPotentialGeneratedImageUrl] = useState('');
    const [suggestedLoading, setSuggestedLoading] = useState(false);
    const [currentModalImage, setCurrentModalImage] = useState(null);
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
    const [isOpenBuyCreditsgModal, setIsOpenBuyCreditsgModal] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState(initialPaymentInfo);

    const fileSelectRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setErrors({ ...errors, [name]: !value });
        setProject({ ...project, [name]: value });
    };

    useEffect(() => {
        edit &&
            setProject({
                name: currentProject.name,
                typeName: currentProject.typeName,
                description: currentProject.description || '',
                type: currentProject.type || '',
                groupsPerProject: validateIncomingTeamsCount(currentProject.groupsPerProject),
                peoplePerGroup: validateIncomingTeamsCount(currentProject.peoplePerGroup),
                canWorkIndividually: Boolean(currentProject.canWorkIndividually),
                canChangeTeams: Boolean(currentProject.canChangeTeams),
                image: currentProject.image || initialState.image,
                imagesLibrary: currentProject.imagesLibrary || initialState.imagesLibrary,
            });

        if (currentProject.image && currentProject.image.name) {
            const { date, size, id } = currentProject.image;
            const originalName = currentProject.image.originalName;
            setUploadInfo({ ...initialUpload, date, size, name: originalName, uploaded: true, id: id });
        }
    }, [currentProject, edit]);

    const validateProjectFields = () => {
        let isErrors = false;

        if (!project.name.trim()) {
            setErrors((prevErrors) => ({ ...prevErrors, name: true }));
            isErrors = true;
        }
        if (!project.type) {
            isErrors = true;
            setErrors((prevErrors) => ({ ...prevErrors, type: true }));
        }
        if (!project.typeName.trim()) {
            isErrors = true;
            setErrors((prevErrors) => ({ ...prevErrors, typeName: true }));
        }

        return !isErrors;
    };

    const openImageModal = (newImage) => {
        setCurrentModalImage(newImage);
        setIsOpenImage(true);
    };

    const createProject = () => {
        if (!validateProjectFields()) return;

        if (project.name.trim()) {
            const params = {
                name: project.name.trim(),
                description: project.description,
                type: project.type,
                typeName: project.typeName.trim(),
                groupsPerProject: validateOutgoingTeamsCount(project.groupsPerProject),
                peoplePerGroup: validateOutgoingTeamsCount(project.peoplePerGroup),
                canWorkIndividually: project.canWorkIndividually,
                canChangeTeams: project.canChangeTeams,
                image: project.image,
                imagesLibrary: project.imagesLibrary.map((image) => {
                    const imageCopy = {...image}
                    delete imageCopy.imageSource;
                    return imageCopy
                }),
            };

            delete params.image.imageSource

            const onSuccessCallback = (data) => {
                setIsPending(false);
                setProjects((prev) => [
                    ...prev,
                    {
                        ...data,
                        cellsCount: {
                            attributes: 0,
                            components: 0,
                            exploredDetails: 0,
                            exploredSubtractions: 0,
                            green: 0,
                            red: 0,
                            total: 0,
                            totalDetails: 0,
                            totalIdeas: 0,
                            totalSubtractions: 0,
                            yellow: 0,
                        },
                    },
                ]);

                if (match?.params?.folderId) {
                    moveProjectToFolder && moveProjectToFolder(data.id, match?.params?.folderId);
                }

                resetProject();
                onSuccess();
            };

            const onErrorCallback = (error) => {
                console.error(error);
                setIsPending(false);
            };

            setIsPending(true);
            requestCreateProject(params, onSuccessCallback, onErrorCallback);
        }
    };

    const resetProject = () => {
        setProject(initialState);
        handleCloseDialog();
    };

    const updateProject = () => {
        if (!validateProjectFields()) return;

        if (project.name.trim()) {
            const params = {
                id: currentProject.id,
                name: project.name.trim(),
                typeName: project.typeName.trim(),
                description: project.description,
                type: project.type,
                groupsPerProject: validateOutgoingTeamsCount(project.groupsPerProject),
                peoplePerGroup: validateOutgoingTeamsCount(project.peoplePerGroup),
                canWorkIndividually: project.canWorkIndividually,
                canChangeTeams: project.canChangeTeams,
                teamId: currentProject.teamId || '',
                image: project.image,
                imagesLibrary: project.imagesLibrary.map((image) => {
                    const imageCopy = { ...image };
                    delete imageCopy.imageSource;
                    return imageCopy;
                }),
            };

            delete params.image.imageSource

            const onSuccessCallback = () => {
                setProjects &&
                    setProjects((prev) => [
                        ...prev.filter((project) => project.id !== currentProject.id),
                        { ...currentProject, ...params },
                    ]);
                setIsPending(false);
                resetProject();
                onSuccess();
            };

            const onErrorCallback = (error) => {
                console.error(error);
                setIsPending(false);
            };

            setIsPending(true);
            requestUpdateProject(params, onSuccessCallback, onErrorCallback);
        }
    };

    const selectProjectImageFromLibrary = (index) => {
        setProject({ ...project, image: project.imagesLibrary[index] });
    };

    const removeImageFromLibrary = (id) => {
        const imagesLibrary = [...project.imagesLibrary];
        const indexOfSelectedImage = imagesLibrary.findIndex((image) => image.id === id);

        if (id === project.image.id) {
            const newImageIndex = indexOfSelectedImage
                ? imagesLibrary.length % indexOfSelectedImage
                : imagesLibrary.length - 1;

            setProject({
                ...project,
                image: imagesLibrary.length - 1 ? imagesLibrary[newImageIndex] : initialState.image,
                imagesLibrary: imagesLibrary.filter((image) => image.id !== id),
            });
            return newImageIndex;
        }

        setProject({ ...project, imagesLibrary: imagesLibrary.filter((image) => image.id !== id) });
        return imagesLibrary.findIndex((image) => image.id === project.image.id);
    };

    const handleImageUpload = (file, imageSource = null) => {
        const CancelToken = axios.CancelToken;

        const formData = new FormData();
        formData.append('image', file, file.name);
        setUploadInfo(() => ({ ...initialUpload, name: file.name, size: file.size }));

        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
                const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadInfo((state) => ({ ...state, progress: completed }));
            },
            cancelToken: new CancelToken(function executor(c) {
                setUploadInfo((state) => ({ ...state, cancel: c }));
            }),
        };

        const onSuccess = (response) => {
            setUploadInfo((state) => ({ ...state, uploaded: true, date: new Date(), id: response.imageId }));

            const image = { name: response.filename, originalName: file.name, id: response.imageId, imageSource };

            if (!project.imagesLibrary.length) {
                setProject({ ...project, imagesLibrary: [...project.imagesLibrary, image], image });
                return;
            }

            setProject({ ...project, imagesLibrary: [...project.imagesLibrary, image] });
        };

        const onError = (error) => {
            console.error(error);
            if (uploadInfo.name) setUploadInfo({ ...initialUpload });
        };

        uploadImage(formData, config, onSuccess, onError);
    };

    const handleCancelClick = () => {
        uploadInfo.cancel && uploadInfo.cancel('Canceled by user');
        setUploadInfo({ ...initialUpload });
        setProject({ ...project, image: {} });
        setPathImage('');
    };

    const handleCloseDialog = () => {
        if (!edit && uploadInfo.name) {
            if (!uploadInfo.uploaded) {
                uploadInfo.cancel && uploadInfo.cancel('Canceled by user');
            }
            setUploadInfo({ ...initialUpload });
        }
        closeDialog && closeDialog();
    };

    const handleSubmitEnter = (event) => {
        if (!isPending && event.key === 'Enter') {
            edit ? updateProject() : createProject();
        }
    };

    const validateFile = (file) => {
        if (!file || file.size > 2097152) {
            setErrorMessage({
                openErrorModal: true,
                message: 'Uploaded file is too big, max image size is 2 MB',
                headerError: getTranslation('ERROR_MESSAGE_MODAL_HEADER'),
            });
            return false;
        }
        return true;
    };

    const handleFileUpload = (event) => {
        const file = event.target ? event.target.files[0] : event[0];
        if (!validateFile(file)) return;
        const reader = new FileReader();
        reader.onload = (e) => {
            const buffer = e.target.result;
            const imageUrl = `data:image/png;base64,${Buffer.from(buffer).toString('base64')}`;
            handleImageUpload(file, imageUrl);
        };
        reader.readAsArrayBuffer(file);
    };

    const convertImage = async (imageBase64) => {
        try {
            const base64Response = await fetch(`data:image/png;base64,${imageBase64}`);
            const blobImage = await base64Response.blob();

            let dt = new DataTransfer();
            dt.items.add(new File([blobImage], 'image.png', { type: blobImage.type }));
            const fileImage = dt.files[0];
            const bufferImage = await fileImage.arrayBuffer();

            const reader = new FileReader();

            reader.onload = () => {
                const imageUrl = `data:image/png;base64,${Buffer.from(bufferImage).toString('base64')}`;
                setPotentialGeneratedImageUrl(imageUrl);
            };

            reader.readAsArrayBuffer(fileImage);

            return fileImage;
        } catch (error) {
            setErrorMessage({
                openErrorModal: true,
                message: error,
                headerError: getTranslation('ERROR_MESSAGE_MODAL_HEADER'),
            });
        }
    };

    const handleAcceptPotentialImage = () => {
        setGeneratedImage(potentialGeneratedImageUrl);
        handleImageUpload(potentialGeneratedImageFile, potentialGeneratedImageUrl);
        setIsOpenGenerateImageModal(false);
    };

    const generateImageForProject = () => {
        if (!project.description.trim()) {
            setErrorMessage({
                openErrorModal: true,
                message: getTranslation('EDIT_PROJECT_MESSAGE_MODAL_CHECK_DESCRIPTION_TEXT'),
                headerError: getTranslation('EDIT_PROJECT_MESSAGE_MODAL_CHECK_DESCRIPTION_HEADER'),
            });
            return;
        }

        setIsOpenGenerateImageModal(true);

        const onSuccess = async (imageBase64) => {
            const fileImage = await convertImage(imageBase64);

            setPathImage(`data:image/png;base64,${imageBase64}`);
            setPotentialGeneratedImageFile(fileImage);
            setSuggestedLoading(false);
        };

        const onError = (error) => {
            setIsOpenGenerateImageModal(false);
            const { message, code } = (error.response && error.response.data);
            
            if (code === "30840") {
                setIsOpenWarningModal(true);
                setSuggestedLoading(false);
                return
            }
            
            setErrorMessage({
                openErrorModal: true,
                message: getTranslation(message) || error,
                headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER")
            });
        };

        setSuggestedLoading(true);

        getGeneratedImageForProject(
            {
                descriptionOfProject: project.description,
                language,
            },
            onSuccess,
            onError
        );
    };

    const closeErrorWindow = () => {
        setErrorMessage({
            openErrorModal: false,
            message: '',
            headerError: '',
        });
    };

    const isImagesLibraryFull = project.imagesLibrary.length > 9;

    const setMaxImageLibraryError = () => {
        setErrorMessage({
            openErrorModal: true,
            message: (
                <p className='flex-column mt-25px'>
                    <span style={{ margin: '0' }}>{getTranslation('IMAGE_LIBRARY_MAX_SIZE_ERROR_IMAGES_COUNT')}</span>
                    <span>{getTranslation('IMAGE_LIBRARY_MAX_SIZE_ERROR_DELETE')}</span>
                </p>
            ),
            headerError: getTranslation('IMAGE_LIMIT_REACHED'),
        });
    };

    const getLabelForProjectTypeName = useMemo(() => {
        switch (project.type) {
            case 'service':
                return getTranslation('EDIT_PROJECT_DIALOG_OVERVIEW_TAB_LABEL_SERVICE_NAME');
            case 'process':
                return getTranslation('EDIT_PROJECT_DIALOG_OVERVIEW_TAB_LABEL_PROCESS_NAME');
            default:
                return getTranslation('EDIT_PROJECT_DIALOG_OVERVIEW_TAB_LABEL_PRODUCT_NAME');
        }
    }, [project.type]);

    const enableTeamsTab = canProjectBeCirculated(currentProject);

    return (
        <Dialog closeDialog={handleCloseDialog} closeOnClickOutside={false}>
            <div className='project-dialog-wrapper'>
                <div style={{ height: 175 }} className='project-dialog-header flex-column'>
                    <div className='flex justify-space-between width-100 project-dialog-header-top'>
                        <div className='flex align-center'>
                            <img className='project-dialog-header-icon' src={ProjectIcon} alt='' />
                            <span className='project-dialog-title'>{getTranslation('EDIT_PROJECT_DIALOG_TITLE')}</span>
                        </div>
                        <img src={CloseIcon} alt='' className='pointer' onClick={() => handleCloseDialog()} />
                    </div>
                    <div style={{ marginTop: 24 }} className='flex width-100 height-100'>
                        <CustomInput
                            customInputClass={`${errors.name && 'border-error'}`}
                            autoFocus={true}
                            value={project.name}
                            onChange={handleChange}
                            name='name'
                            containerClass='flex-column width-100 mb-10 project-name'
                            onKeyDown={handleSubmitEnter}
                            label={getTranslation('EDIT_PROJECT_DIALOG_OVERVIEW_TAB_LABEL__PROJECT_NAME')}
                        />
                    </div>
                </div>

                <div style={{ paddingTop: 7 }} className='project-dialog-body flex-column'>
                    {currentTab === 0 && (
                        <>
                            <div className='flex flex-column mb-10'>
                                <span className='project-dialog-label align-self-start'>
                                    {getTranslation('EDIT_PROJECT_DIALOG_OVERVIEW_TAB_PROJECT_TYPE')}
                                </span>
                                <RadioInput
                                    background='white'
                                    showBorder
                                    textClass='project-type-radio'
                                    colorSpan='#242c5c'
                                    options={projectOptions}
                                    value={project.type}
                                    setValue={(e) => {
                                        if (
                                            currentProject.sortOrder &&
                                            currentProject.sortOrder.components.length !== 0
                                        )
                                            return setOpenModal({
                                                ...openModal,
                                                error: isProcessType
                                                    ? 'CONFORMATION_MODAL_ERROR_CHANGE_PROJECT_TYPE_STEP'
                                                    : 'CONFORMATION_MODAL_ERROR_CHANGE_PROJECT_TYPE_COMPONENT',
                                            });
                                        setProject({ ...project, type: e });
                                    }}
                                    styleRadioInput={{
                                        height: 50,
                                        border: errors.type && !project.type && '1px solid rgb(203, 26, 26)',
                                    }}
                                />
                            </div>
                            <CustomInput
                                value={project.typeName ? project.typeName : ''}
                                onChange={handleChange}
                                name='typeName'
                                customInputClass={`${errors.typeName && 'border-error'} project-name-placeholder`}
                                containerClass='flex-column width-100 mb-10 project-name'
                                onKeyDown={handleSubmitEnter}
                                label={getLabelForProjectTypeName}
                                placeholder={getTranslation('PROJECT_EDIT_MODAL_PLACEHOLDER')}
                            />
                            <CustomTextarea
                                value={project.description}
                                onChange={handleChange}
                                name='description'
                                label={getTranslation('EDIT_PROJECT_DIALOG_OVERVIEW_TAB_LABEL_DESCRIPTION')}
                                containerClass='flex-column mb-10'
                            />

                            <div style={{ marginTop: project.imagesLibrary.length ? '-40px' : '-30px' }}>
                                {project.imagesLibrary.length > 0 && (
                                    <>
                                        <input
                                            type='file'
                                            ref={fileSelectRef}
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFileUpload(e)}
                                        />
                                        <button
                                            className={`project-edit-modal__upload-image-button project-edit-modal__button-ai
                                    ${isImagesLibraryFull && 'project-edit-modal__upload-image-button_disabled'}`}
                                            onClick={() =>
                                                isImagesLibraryFull
                                                    ? setMaxImageLibraryError()
                                                    : fileSelectRef.current.click()
                                            }
                                        >
                                            {getTranslation("UPLOAD_IMAGES")}
                                        </button>
                                    </>
                                )}

                                <button
                                    className={`project-edit-modal__button-ai 
                                project-edit-modal__button-ai_image 
                                ${
                                    (isImagesLibraryFull || !project.description.trim()) &&
                                    'project-edit-modal__button-ai_image-disabled'
                                }`}
                                    onClick={() =>
                                        isImagesLibraryFull ? setMaxImageLibraryError() : generateImageForProject()
                                    }
                                    style={{ left: project.imagesLibrary.length ? '56px' : '167px' }}
                                >
                                    <p>
                                        <AIGenerateIcon style={{ marginRight: '10px', width: '18px' }} />
                                        <span>{getTranslation('GENERATE_IMAGES_BUTTON')}</span>
                                    </p>
                                    <p>{getTranslation('GENERATE_IMAGES_BUTTON_AI_CREDITS')}</p>
                                </button>

                                {project.imagesLibrary.length ? (
                                    <ImagesCarousel
                                        openImageModal={openImageModal}
                                        imagesLibrary={project.imagesLibrary}
                                        currentImageId={project.image.id}
                                        selectCurrentDependencyImageFromLibrary={selectProjectImageFromLibrary}
                                        startIndex={project.imagesLibrary.findIndex(
                                            (image) => image.id === project.image.id
                                        )}
                                        removeImageFromLibrary={removeImageFromLibrary}
                                        width={550}
                                    />
                                ) : (
                                    <ImageUploader
                                        label={getTranslation('EDIT_PROJECT_DIALOG_OVERVIEW_TAB_LABEL_PROJECT_IMAGE')}
                                        uploadInfo={uploadInfo}
                                        handleCancelClick={handleCancelClick}
                                        handleFileSelect={handleImageUpload}
                                        displayPreview
                                        isLoadingImage={false}
                                        height={200}
                                        maxHeight={200}
                                        currentProjectImageId={project.image.id}
                                        openImage={() => setIsOpenImage(true)}
                                        generatedImage={generatedImage}
                                    />
                                )}
                            </div>
                        </>
                    )}
                    {currentTab === 1 && (
                        <>
                            <div className='flex justify-space-between align-center mb-30'>
                                <span className='project-dialog-label'>
                                    {getTranslation('EDIT_PROJECT_DIALOG_TEAMS_TAB_GROUP_NUMBER')}
                                </span>
                                <CustomNumberInput
                                    value={project.groupsPerProject}
                                    onChange={(e) => setProject({ ...project, groupsPerProject: e })}
                                    minValue={0}
                                    customLabels={numberInputLabels}
                                    disabled={!enableTeamsTab}
                                />
                            </div>

                            <div className='flex justify-space-between align-center mb-30'>
                                <span className='project-dialog-label'>
                                    {getTranslation('EDIT_PROJECT_DIALOG_TEAMS_TAB_PEOPLE_NUMBER')}
                                </span>
                                <CustomNumberInput
                                    value={project.peoplePerGroup}
                                    onChange={(e) => setProject({ ...project, peoplePerGroup: e })}
                                    minValue={0}
                                    customLabels={numberInputLabels}
                                    disabled={!enableTeamsTab}
                                />
                            </div>

                            <div className='flex align-center justify-space-between mb-30'>
                                <span className='project-dialog-label'>
                                    {getTranslation('EDIT_PROJECT_DIALOG_TEAMS_TAB_WORK_INDIVIDUALLY')}
                                </span>
                                <CustomSwitch
                                    invert
                                    value={project.canWorkIndividually}
                                    setValue={(e) => setProject({ ...project, canWorkIndividually: e })}
                                    labels={labels}
                                    disabled={!enableTeamsTab}
                                />
                            </div>
                            <div className='flex align-center justify-space-between mb-30'>
                                <span className='project-dialog-label'>
                                    {getTranslation('EDIT_PROJECT_DIALOG_TEAMS_TAB_CHANGE_TEAM')}
                                </span>
                                <CustomSwitch
                                    invert
                                    value={project.canChangeTeams}
                                    setValue={(e) => setProject({ ...project, canChangeTeams: e })}
                                    labels={labels}
                                    disabled={!enableTeamsTab}
                                />
                            </div>
                        </>
                    )}
                </div>

                {openModal.error && (
                    <ConfirmationModal
                        closeDialog={() => setOpenModal({ ...openModal, error: '' })}
                        message={getTranslation(openModal.error)}
                        buttonText={'CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT'}
                        onConfirm={() => setOpenModal({ ...openModal, error: '' })}
                        messageWidth={440}
                        messageHeight={95}
                        className='modal-type-error'
                    />
                )}

                <div className='project-dialog-buttons'>
                    <button
                        className='project-dialog-button dark-blue-btn'
                        disabled={isPending}
                        onClick={() => {
                            edit ? updateProject() : createProject();
                        }}
                    >
                        {getTranslation('EDIT_PROJECT_DIALOG_BUTTON')}
                    </button>
                </div>
            </div>

            {errorMessage.openErrorModal && (
                <ErrorMessageModal
                    message={errorMessage.message}
                    closeModalWindow={closeErrorWindow}
                    textButton={getTranslation('CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT')}
                    handleButton={closeErrorWindow}
                    header={errorMessage.headerError}
                />
            )}

            {isOpenGenerateImageModal && (
                <GenerateImageModal
                    handleAcceptPotentialImage={handleAcceptPotentialImage}
                    closeModalWindow={() => setIsOpenGenerateImageModal(false)}
                    header={getTranslation('OPEN_AI_LOADER_HEADER_IMAGE')}
                    message={getTranslation('OPEN_AI_LOADER_IMAGE')}
                    imageData={potentialGeneratedImageUrl}
                    suggestedLoading={suggestedLoading}
                />
            )}

            {paymentInfo.key && (
                <CheckoutModal
                    isOpenAISubscription
                    closeDialog={() => setPaymentInfo((prev) => ({ ...prev, key: '' }))}
                    subscriptionInfo={paymentInfo}
                    history={history}
                    mode='payment'
                    setGeneratedOpenAIRequests={setGeneratedOpenAIRequests}
                />
            )}

            {isOpenBuyCreditsgModal && (
                <BuyMoreAICreditsModal
                    setPaymentInfo={setPaymentInfo}
                    closeDialog={() => setIsOpenBuyCreditsgModal(false)}
                />
            )}

            {isOpenWarningModal && (
                <WarningNotTokensModal
                    closeDialog={() => {
                        setIsOpenWarningModal(false);
                    }}
                    openBuyModal={() => setIsOpenBuyCreditsgModal(true)}
                    history={history}
                />
            )}

            {isOpenImage && (
                <ImageModal
                    closeModal={() => setIsOpenImage(false)}
                    imageData={currentModalImage}
                    altImage={getTranslation('IMAGE_MODAL_ALT_SKETCH_PROJECT')}
                />
            )}
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    language: state.auth.userInfo.language,
});

const mapDispatchToProps = {
    setGeneratedOpenAIRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditModal);
