import axios from 'axios';
import { handleSuccess, handleError } from './handlers';
import { baseApiUrl } from '../common/constants';

const baseUrl = `${baseApiUrl}/projects`;

export const getProject = async (params, onSuccess, onError) => {
    axios
        .get(`${baseUrl}/project`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const getProjects = async (params, onSuccess, onError) => {
    axios
        .get(`${baseUrl}`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const createProject = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/create`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const updateProject = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/update`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const updateOrder = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/order`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const deleteProject = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/delete`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const duplicateProject = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/duplicate`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const archiveProject = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/archive`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};
