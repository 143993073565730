import React, { useRef, useEffect } from 'react'
import { getTranslation } from '../../helpers/getLanguage';

import CellDropdownElem from './CellDropdownElem'

const CellDropdown = ({
    row,
    column,
    rows,
    setSelectedCell,
    handleOpenDetails
}) => {
    const ref = useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        document.addEventListener('keydown', handleKeyDown, true);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
            document.removeEventListener('keydown', handleKeyDown, true);
        };
    });

    const handleClickOutside = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            setSelectedCell({ row: null, column: null, isLocked: false });
        }
    };

    const handleKeyDown = e => {
        if (e.key === 'Escape') {
            setSelectedCell({ row: null, column: null, isLocked: false });
        }
    };

    return (
        <div className="cell-dropdown flex-column align-center" ref={ref}>
            {rows.map(details =>
                <CellDropdownElem
                    key={details.index}
                    row={row}
                    column={column}
                    details={details}
                    handleOpenDetails={handleOpenDetails}
                />
            )}
            <button className="open-details-button medium flex align-center" onClick={() => handleOpenDetails(row, column, null)}>
                <span>{getTranslation("ADD_NEW_IDEA_BUTTON")}</span>
                <div className="button-triangle-icon"></div>
            </button>
        </div>
    );
}

export default CellDropdown;